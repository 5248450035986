import React from 'react';
import Img from 'gatsby-image';
import get from 'lodash.get';
import { Link } from 'gatsby-plugin-react-intl';
import { ConditionalWrapper } from './ConditionalWrapper';

import { Slider, SliderHero, SharedStyles } from '../kenra-storybook/index';

const {
    StSliderHeroItem,
    StSliderHeroImg,
    StSliderHeroImgWeb,
    StSliderHeroImgMobile,
} = SliderHero;

const { StLink } = SharedStyles;

const swiperUseEffect = function(swiper) {
    const slides = swiper.slides;
    Object.values(slides).forEach(elem => {
        if (
            elem.querySelector &&
            elem.classList.contains('swiper-slide-duplicate')
        ) {
            if (elem.querySelector('picture > img')) {
                elem.querySelector('picture > img').style.opacity = 1;
            }
        }
    });
};

export default function EmbeddedSlider({ slides, showText }) {
    return (
        <Slider
            autoPlay
            embeddedSpace
            swiperUseEffect={swiper => swiperUseEffect(swiper)}
        >
            {slides.map((slide, index) => (
                <StSliderHeroItem key={`${slide.id}_${index}`}>
                    {!slide.mobileImage && (
                        <StSliderHeroImg>
                            <ConditionalWrapper
                                condition={slide.slideLink}
                                wrapper={children => (
                                    <Link to={slide.slideLink}>{children}</Link>
                                )}
                            >
                                {slide.image &&
                                    slide.image.localFile &&
                                    slide.image.localFile.big &&
                                    slide.image.localFile.big.fluid && (
                                        <Img
                                            fluid={get(
                                                slide.image,
                                                'localFile.big.fluid'
                                            )}
                                            loading="eager"
                                            imgStyle={{
                                                opacity: 1,
                                            }}
                                            placeholderStyle={{
                                                opacity: 0,
                                            }}
                                        />
                                    )}
                            </ConditionalWrapper>
                        </StSliderHeroImg>
                    )}
                    {slide.mobileImage && (
                        <div>
                            <StSliderHeroImgWeb>
                                <ConditionalWrapper
                                    condition={slide.slideLink}
                                    wrapper={children => (
                                        <Link to={slide.slideLink}>
                                            {children}
                                        </Link>
                                    )}
                                >
                                    {slide.image &&
                                        slide.image.localFile &&
                                        slide.image.localFile.big &&
                                        slide.image.localFile.big.fluid && (
                                            <Img
                                                fluid={get(
                                                    slide.image,
                                                    'localFile.big.fluid'
                                                )}
                                                loading="eager"
                                                imgStyle={{
                                                    opacity: 1,
                                                }}
                                                placeholderStyle={{
                                                    opacity: 0,
                                                }}
                                            />
                                        )}
                                </ConditionalWrapper>
                            </StSliderHeroImgWeb>
                            <StSliderHeroImgMobile>
                                <ConditionalWrapper
                                    condition={slide.slideLink}
                                    wrapper={children => (
                                        <Link to={slide.slideLink}>
                                            {children}
                                        </Link>
                                    )}
                                >
                                    {slide.mobileImage &&
                                        slide.mobileImage.localFile &&
                                        slide.mobileImage.localFile.big &&
                                        slide.mobileImage.localFile.big
                                            .fluid && (
                                            <Img
                                                fluid={get(
                                                    slide.mobileImage,
                                                    'localFile.big.fluid'
                                                )}
                                                loading="eager"
                                                imgStyle={{
                                                    opacity: 1,
                                                }}
                                                placeholderStyle={{
                                                    opacity: 0,
                                                }}
                                            />
                                        )}
                                </ConditionalWrapper>
                            </StSliderHeroImgMobile>
                        </div>
                    )}
                </StSliderHeroItem>
            ))}
        </Slider>
    );
}
