import React from 'react';
import { graphql } from 'gatsby';

import Layout from 'components/Layout';
import HeroLanding from 'components/HeroLanding';
import SEO from 'components/seo';
import get from 'lodash.get';

export default function HeroLandingPage(props) {
    let {
        data: { contentfulHeroLandingPage, allContentfulProduct },
    } = props;

    return (
        <Layout>
            <SEO
                description={get(
                    contentfulHeroLandingPage,
                    'seoListing.description.description'
                )}
                title={get(contentfulHeroLandingPage, 'seoListing.seoTitle')}
                slug={'be-a-hero-today'}
            />
            <HeroLanding
                page={contentfulHeroLandingPage}
                products={allContentfulProduct}
            />
        </Layout>
    );
}

export const query = graphql`
    query($locale: String) {
        contentfulHeroLandingPage(node_locale: { eq: $locale }) {
            ...ContentfulHeroLandingFields
        }
        allContentfulProduct(filter: { node_locale: { eq: $locale } }) {
            nodes {
                title
                shopifyProduct {
                    handle
                }
                productItem {
                    title
                    images {
                        id
                        localFile {
                            publicURL
                            thumbnail: childImageSharp {
                                resize(width: 100, height: 100) {
                                    src
                                }
                            }
                        }
                    }
                }
                node_locale
            }
        }
    }
`;
