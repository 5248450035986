import React from 'react';
import get from 'lodash.get';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import HeroSlider from 'components/HeroSlider';
import EmbeddedSlider from 'components/EmbeddedSlider';
import { getYouTubeId, getPreviewUrl, getEmbedUrl } from 'helpers/youtube';
import { injectIntl, Link } from 'gatsby-plugin-react-intl';
import { multiline, formatHTML } from 'helpers/text';
import { getProductUrl } from 'helpers/url';
import CustomLink from './Link';

import {
    Spacing,
    SharedStyles,
    Container,
    VideoRow,
    ProductList,
    ImageTextTile,
    BannerVideo,
    SingleImageBanner,
} from '../kenra-storybook/index';
const {
    StSectionInfo,
    StTitle,
    StLinkMore,
    StSectionMobileViewMore,
    StSectionTitle,
    StSectionBody,
} = SharedStyles;
const HeroLanding = ({ intl, page, products }) => {
    let productItems = [];

    if (page.products) {
        productItems = page.products.map(product => {
            let contentfulProduct = products.nodes.find(
                sp => sp.shopifyProduct.handle === product.shopifyProduct.handle
            );

            return {
                link: getProductUrl(contentfulProduct.shopifyProduct.handle),
                img: get(
                    contentfulProduct,
                    'productItem.images.0.localFile.publicURL'
                ),
                title: get(contentfulProduct, 'productItem.title'),
            };
        });
    }

    return (
        <>
            <Spacing removeSpaceTop>
                <HeroSlider slides={page.slides} />
            </Spacing>

            <Spacing>
                <StSectionInfo>
                    <Container>
                        <StTitle>
                            <h2>{page.title}</h2>
                        </StTitle>
                        <StSectionBody
                            dangerouslySetInnerHTML={{
                                __html: formatHTML(JSON.parse(page.text.raw || '{}')),
                            }}
                        ></StSectionBody>
                        <StLinkMore styleBig color={page.buttonLinkColor}>
                            <CustomLink href={page.buttonLink}>
                                {page.buttonLinkText}
                            </CustomLink>
                        </StLinkMore>
                    </Container>
                </StSectionInfo>
            </Spacing>

            <Spacing>
                <EmbeddedSlider slides={page.heroSlides} />
            </Spacing>

            {page.storyVideo && (
                <Spacing>
                    <StSectionInfo>
                        <Container>
                            <StTitle>
                                <h2>
                                    {intl.formatMessage({
                                        id: 'kenra-our-stories',
                                    })}
                                </h2>
                            </StTitle>
                            <StSectionBody>
                                <BannerVideo
                                    maxWidthMedium
                                    videoUrl={page.storyVideo}
                                    videoType={'youtube'}
                                    autoPlay={false}
                                    showControls={false}
                                />
                            </StSectionBody>
                        </Container>
                    </StSectionInfo>
                </Spacing>
            )}

            {page.meetTiles && (
                <Spacing>
                    <ImageTextTile
                        rowRevers
                        items={page.meetTiles.map(tile => {
                            let images = [];
                            tile.images.forEach(image => {
                                images.push(get(image, 'localFile.publicURL'));
                            });
                            return {
                                images: images,
                                title: tile.title,
                                text: documentToReactComponents(JSON.parse(tile.text.raw || '{}')),
                            };
                        })}
                    />
                </Spacing>
            )}

            {page.products && (
                <Spacing removeSpaceTop removeSpaceBottom>
                    <StSectionInfo>
                        <Container>
                            <StTitle>
                                <h2>
                                    {intl.formatMessage({
                                        id: 'product-products-used',
                                    })}
                                </h2>
                            </StTitle>
                            <StSectionBody>
                                <ProductList items={productItems} center />
                            </StSectionBody>
                        </Container>
                    </StSectionInfo>
                </Spacing>
            )}

            {page.tutorial && (
                <Spacing removeSpaceTop>
                    <StSectionInfo>
                        <StTitle>
                            <Container>
                                <h2>
                                    {intl.formatMessage({
                                        id: 'kenra-tutorial',
                                    })}
                                </h2>
                            </Container>
                        </StTitle>
                        {documentToReactComponents(JSON.parse(page.tutorialVideoText.raw || '{}'))}
                        <Container>
                            <VideoRow
                                items={page.tutorial.map(video => {
                                    let youTubeId = getYouTubeId(
                                        video.youtubeLink
                                    );
                                    return {
                                        btnSizeMedium: true,
                                        bgImg: getPreviewUrl(youTubeId),
                                        videoType: 'youtube',
                                        autoPlay: true,
                                        videoOptions: [
                                            { src: getEmbedUrl(youTubeId) },
                                        ],
                                    };
                                })}
                            />
                        </Container>
                    </StSectionInfo>
                </Spacing>
            )}
            {page.bottomImageLogo && (
                <SingleImageBanner
                    logo={get(page.bottomImageLogo, 'localFile.publicURL')}
                    title={page.bottomImageTitle}
                    text={page.bottomImageText}
                    backgroundColor={'rgba(0,51,103,0.1)'}
                />
            )}
        </>
    );
};

export default injectIntl(HeroLanding);
